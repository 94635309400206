<template>
  <div>
    <b-card class="card-accent-primary" header-tag="header" footer-tag="footer">
      <b-nav tabs>
        <b-nav-item>
          <router-link :to="'/system-traffic'" :exact="true">Sistem trafiği </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="'/request-log'" :exact="true">İstem trafiği </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="'/networkholter'" :exact="true">Network Holter </router-link>
        </b-nav-item>

        </b-nav-item>
        <b-nav-item active v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/database-document-management'" :exact="true">Veritabanı Döküman Yönetimi </router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('administrator') !== -1)">
          <router-link :to="'/customer-support'" :exact="true"> Müşteri Destek </router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/system-management'" :exact="true">WisdomEra Sistem Yönetimi</router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/data-structure-analyze'" :exact="true">Veri Yapısı Analizi</router-link>
        </b-nav-item>
      </b-nav>
      <b-card bg-variant="primary" style="margin-bottom: 5px;" no-body>
        <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
          Veri Tabanı Döküman Yönetim Ekranı
          <b-button class="pull-right" size="sm" variant="primary" @click="d_showMode ? d_showMode = false : d_showMode = true"><i class="fa fa-edit"></i></b-button>
        </b-card-header>
        <b-row style="padding: 3px;" v-if="d_showMode">
          <b-col sm="12" md="12">
            <b-card class="card-accent-secondary" no-body style="color: black; height: 100%; margin-bottom: 0px;">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                Sorgulama İşlemi
              </b-card-header>
              <b-row style="padding: 3px;">
                <b-col sm="12" lg="2">
                  <b-form-select v-model="d_searchingData.type">
                    <option value="get_with_key"> Key ile getir </option>
                    <option value="query_string"> Query String </option>
                  </b-form-select>
                  <b-form-checkbox v-model="d_searchingData.download"> Dökümanları İndir</b-form-checkbox>
                </b-col>
                <b-col sm="12" lg="2">
                  <b-form-select v-model="d_searchingData.bucket">
                    <option v-for="(buc, buc_ind) in d_bucketList" :value="buc.bucket"> {{ buc.bucket }} </option>
                  </b-form-select>
                </b-col>
                <b-col sm="12" lg="7">
                  <b-card v-if="d_searchingData.type === 'get_with_key'" no-body>
                    <b-form-input v-model="d_searchingData.key" @keydown.enter.native="ClickEnter"></b-form-input>
                  </b-card>
                  <b-card v-if="d_searchingData.type === 'query_string'" no-body>
                    <b-textarea placeholder="select meta() as meta, * from `auth` use keys 'user_xxxxx' şeklinde meta ile birlikte belirtirseniz meta bilgilerini de alabilirsiniz." v-model="d_searchingData.query_string"></b-textarea>
                  </b-card>
                </b-col>
                <b-col sm="12" lg="1">
                  <b-button size="md" variant="success" @click="f_getSearchList()"><i class="fa fa-update"></i>listele</b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
      <b-card style="margin-bottom: 0px;" no-body>
        <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
          Sonuç Listesi
        </b-card-header>
        <template v-for="(doc, doc_ind) in d_searchResult">
          <b-card style="margin-top: 10px; margin-bottom: 0px;" no-body>
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              Sorgu Sonucu
            </b-card-header>
            <b-row style="margin: 0px;">
              <b-col sm="12" md="4">
                <b-row style="margin: 0px;">
                  <b-col sm="12" md="3">
                    Meta ID
                  </b-col>
                  <b-col sm="12" md="9">
                    <span v-if="doc.meta && doc.meta.id">
                     {{ doc.meta.id }}
                    </span>
                  </b-col>
                </b-row>
                <b-row style="margin: 0px;">
                  <b-col sm="12" md="3">
                    Created By
                  </b-col>
                  <b-col sm="12" md="9">
                    <span v-if="doc.meta && doc.meta.created_by">
                    {{ doc.created_by }}
                  </span>
                  </b-col>
                </b-row>
                <b-row style="margin: 0px;">
                  <b-col sm="12" md="3">
                    Created At
                  </b-col>
                  <b-col sm="12" md="9">
                    <span v-if="doc.meta && doc.meta.created_at">
                    {{ doc.created_at }}
                  </span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="12" md="8">
                <div style="overflow-y: auto; height: 300px; white-space: pre;">
                  {{ f_getJsonStringify(doc.data) }}
                </div>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  default as Modal
} from '@/components/widgets/Modal';
import Datepicker from 'wisdom-vue-datepicker';
import moment from 'moment';
import GlobalService from '@/services/global';
import UserService from '@/services/users';
import FileSaver from 'file-saver';

export default {
  name: 'DatabaseDocumentManagement',
  computed: {
    ...mapGetters({
      lang: 'lang',
      help: 'help'
    })
  },
  notifications: {
    showNotification: { // You can have any name you want instead of 'showNotification'
      title: '',
      message: '',
      type: '' // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  },
  data () {
    return {
      d_searchingData: {
        'download': 0,
        'type': 'get_with_key',
        'key': '',
        'key_list': [],
        'query_string': '',
        'bucket': 'wisdom'
      },
      user: {},
      d_searchResult: [],
      d_showMode: true,
      d_bucketList: [
        { 'bucket': 'auth', 'about': '' },
        { 'bucket': 'catalog', 'about': '' },
        { 'bucket': 'default', 'about': '' },
        { 'bucket': 'education', 'about': '' },
        { 'bucket': 'hospital', 'about': '' },
        { 'bucket': 'option', 'about': '' },
        { 'bucket': 'polestar', 'about': '' },
        { 'bucket': 'rule', 'about': '' },
        { 'bucket': 'uploads', 'about': '' },
        { 'bucket': 'wisdom', 'about': '' }
      ]
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {},
  methods: {
    ClickEnter (event) {
      if (event.which === 13) {
        this.f_getSearchList();
      }
    },
    f_getJsonStringify: function (data) {
      return JSON.stringify(data, null, 4);
    },
    f_getSearchList: function () {
      this.d_searchResult = [];
      if (this.d_searchingData.type === 'get_with_key' && !this.d_searchingData.key) {
        this.d_searchingData.key_list = [];
        this.d_searchingData.key_list.push(this.d_searchingData.key);
        alert('Lütfen getirmek istediğiniz dökümana ait key değerini giriniz.');
        return;
      } else if (this.d_searchingData.type === 'query_string' && !this.d_searchingData.query_string) {
        alert('Lütfen getirmek istediğiniz dökümana ait query stringi giriniz.');
        return;
      }
      if (this.d_searchingData.type === 'get_with_key') {
        this.d_searchingData.key_list = [];
        this.d_searchingData.key_list.push(this.d_searchingData.key);
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Vaka listeniz hazırlanıyor. Lütfen bekleyiniz.' } });
      GlobalService.database_query(this.d_searchingData)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          this.showNotification({ 'title': 'Sistem Mesajı', 'message': resp.data.message, 'type': 'warn', 'timeout': 5000 });
          if (resp.data.status === 'success') {
            this.d_searchResult = resp.data.result;
            if (this.d_searchingData.download && this.d_searchResult.length > 0) {
              let x = [];
              for (let i in this.d_searchResult) {
                x.push(this.d_searchResult[i].data);
              }
              let json_file = JSON.stringify(x, null, 4);
              let download_data = [json_file];
              var blob = new Blob(download_data, { type: "text/plain;charset=utf-8" });
              FileSaver.saveAs(blob, "document.json");
            }
          } else {
            alert('error: ' + resp.data.message);
          }
        });
    }
  },
  components: {
    Modal,
    Datepicker
  },
  watch: {}
};

</script>

<style type="text/css">
.s_tdCls {
  white-space: nowrap;
}

.s_approvalNotSelected {
  background-color: white;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

.s_approvalNotSelected:hover {
  background-color: #e6e6e6;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
  /*border-bottom: solid 1px #e6e6e6;*/
}

.s_approvalSelected {
  background-color: #9effc08f;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

.s_approvalSelected:hover {
  background-color: #e6e6e6;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

#s_approvalTypesScrollbar::-webkit-scrollbar-track {
  border-radius: 0 10px 10px 0;
  background-color: #d9e9ea;
}

#s_approvalTypesScrollbar::-webkit-scrollbar {
  width: 10px;
  /*background-color: #F5F5F5;*/
}

#s_approvalTypesScrollbar::-webkit-scrollbar-thumb {
  border-radius: 0 10px 10px 0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

</style>

